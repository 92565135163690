.useful-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 102px;
    margin-top: 16px;
    border-radius: 14px;
    background: rgba(116, 116, 128, 0.08);

    .useful-text {
        font-family: "SFProDisplayBold";
    }

    .useful-buttons {
        display: flex;
        flex-direction: row;
        gap: 12px;

        .useful-buttons-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 6px;
            width: 140px;
            height: 36px;
            border-radius: 999px;
            background: #DBEEEF;

            .useful-buttons-item-img {
                width: 20px;
                height: 20px;
            }

            .useful-buttons-item-text {
                font-family: "SFProDisplayBold";
                color: #00b8b8;
            }
            .useful-buttons-item-text.timeout {
                color: rgba(161, 164, 162, 0.5);
            }
        }

        .useful-buttons-item.timeout {
            background: rgba(161, 164, 162, 0.08);
        }

        .useful-buttons-item.why {
            width: 270px;
        }
    }

    .useful-buttons.why {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}

.useful-container.why {
    height: 150px;
}