.date_action_box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
        display: flex;
        justify-content: center;

        .input-custom {
            width: 300px;
            height: 40px;
            border: 0.5px solid rgba(60, 60, 67, 0.2);
            border-radius: 24px;
            color: #00B8B8;
            box-sizing: border-box;
            padding-right: 35px;
            background: rgba(116, 116, 128, 0.08);
        }

        .input-custom:focus {
            outline: none !important;
            border: 1px solid #00b8b8;
        }

        .input-icon-clear {
            position: absolute;
            top: 23px;
            right: 12px;
            width: 18px;
            height: 18px;
        }
    }

    .feedback-actions-send {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        padding: 0px;
        margin: 5px;
        width: 133px;
        height: 36px;
        background: #00B8B8;
        border-radius: 999px;

        img {
            width: 16px;
        }

        .feedback-actions-send-text {
            color: white;
        }

        .feedback-actions-send-text.disable {
            color: rgba(60, 60, 67, 0.3);
        }

    }

    .feedback-actions-send.disable {
        background: rgba(120, 120, 128, 0.16);
    }
}