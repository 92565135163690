.input-container {
    position: relative;

    .input-icon {
        position: absolute;
        top: 23px;
        left: 10px;
        width: 18px;
        height: 18px;
    }

    .input-custom {
        width: 340px;
        height: 40px;
        margin: 12px auto 16px;
        border: 0.5px solid rgba(60, 60, 67, 0.2);
        border-radius: 24px;
        box-sizing: border-box;
        padding-left: 35px;
        background: rgba(116, 116, 128, 0.08);
    }

    .input-custom:focus {
        outline: none !important;
        border: 1px solid #00b8b8;
    }

    .input-icon-clear {
        position: absolute;
        top: 23px;
        right: 12px;
        width: 18px;
        height: 18px;
    }
}