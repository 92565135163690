.categoryBig-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    margin: 0 auto;

    .banner {
        width: 345px;
    }

    .categoryBig-container_articles {
        margin-bottom: 24px;
        width: 344px;

        .subcategory {

            font-family: "SFProDisplayBold";
            font-size: 28px;
            margin: 10px;
        }
    }

    .categoryBig-item {
        color: black;
    }
}