.feedback-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-radius: 14px;
    background: rgba(116, 116, 128, 0.08);

    .feedback-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        .feedback-text {
            font-family: "SFProDisplayBold";
        }

        .feedback-id {
            font-family: "SFProTextRegular";
            font-size: 14px;
            color: rgba(60, 60, 67, 0.6);
        }
    }

    .input-custom {
        width: 280px;
        min-width: 280px;
        max-width: 280px;
        min-height: 45px;
        max-height: 150px;
        margin: 12px auto 16px;
        border: 0.5px solid rgba(60, 60, 67, 0.2);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5px;
    }

    .input-custom:focus {
        outline: none !important;
        border: 1px solid #00b8b8;
    }

    .feedback-actions {
        width: 280px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        img {
            width: 22px;
        }

        .feedback-actions-send {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            padding: 0px;
            width: 133px;
            height: 36px;
            background: #00B8B8;
            border-radius: 999px;

            img {
                width: 16px;
            }

            .feedback-actions-send-text {
                color: white;
            }

            .feedback-actions-send-text.disable {
                color: rgba(60, 60, 67, 0.3);
            }

        }

        .feedback-actions-send.disable {
            background: rgba(120, 120, 128, 0.16);
        }
    }

    .preview_container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        width: 80%;
        margin-top: 10px;

        .preview {
            width: 30px;
            height: 50px;
            border-radius: 5px;
        }
    }
}

.sended {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin: 30px 0px 20px 0px;
    border-radius: 999px;
    background: #DBEEEF;
    .sended-text {
        font-family: "SFProDisplayBold";
        font-weight: 400;
        font-size: 1rem;
        color: #00b8b8;
    }
}