@font-face {
    font-family: "SFProDisplayBold";
    src: local("SFProDisplayBold"), url("./fonts/SFProDisplay/SF-Pro-Display-Bold.otf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "SFProDisplayLight";
    src: local("SFProDisplayLight"), url("./fonts/SFProDisplay/SF-Pro-Display-Light.otf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "SFProDisplayRegular";
    src: local("SFProDisplayRegular"), url("./fonts/SFProDisplay/SF-Pro-Display-Regular.otf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "SFProTextRegular";
    src: local("SFProTextRegular"), url("./fonts/SFProText/SFProText-Regular.ttf") format("truetype");
    font-weight: 400;
}


body {
    margin: 0;
    padding: 0;
}

* {
    font-family: "SFProDisplayRegular"
}