.home-container {
    /* Позиционирование */
    /* Блочная модель */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    margin: 0 auto;
    /* Типографика */
    /* Оформление */
    /* Анимация */
    /* Разное */

    .home-container_header {
        width: 100%;
    }

    input {
        font-size: 16px;
        opacity: 0.7;
    }

    input::placeholder {
        font-size: 16px;
    }

    .home-container_popular {
        margin-bottom: 24px;
        width: 344px;
    }

    .home-container_categories {
        display: grid;
        grid-template-columns: 165px 165px;
        grid-row: auto auto;
        column-gap: 14px;
        row-gap: 14px;
        width: 344px;
    }
}