.popular-header {
    font-family: "SFProDisplayBold";
    font-size: 28px;
    margin-bottom: 10px;
}

.MuiAccordion-root::before {
    background: transparent !important;
}

.popular-item {
    color: #00B8B8;
}

.info {
    display: flex;
    justify-content: center;
    color: rgba(60, 60, 67, 0.5);
    ;
    margin-bottom: 20px;
}

.zendesk-text {
    font-size: 14px;
    word-wrap: break-word;
}

a:link {
    color: #00b8b8;
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: #00b8b8;
    background-color: transparent;
    text-decoration: none;
}

.verification_img_container {
    display: flex;
    justify-content: center;

    img {
        width: 200px;
        height: 200px;
        border-radius: 25px;
    }

}

.useful-buttons-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 36px;
    border-radius: 999px;
    background: #00b8b8;

    .useful-buttons-item-text {
        color: white;
    }
}